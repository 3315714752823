import React from "react";
import { Link, useStaticQuery } from "gatsby";
import { Typography, Button } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { FaCalculator } from "react-icons/fa";
import Image from "gatsby-image/withIEPolyfill";

const query = graphql`
  {
    file(relativePath: { eq: "hero3.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

const useStyle = makeStyles((theme) => ({
  sectionCenter: {
    ...theme.section.center,
  },
  btn: {
    marginTop: "2rem",
    fontSize: "1rem",
    padding: "10px 30px",
    borderRadius: "50px",
  },
  flexCenter: {
    ...theme.section.flexCenter,
    padding: "0px 50px ",
  },
  icone: {
    width: "60px",
    marginBottom: "30px",
  },
  title: {
    fontSize: "2.5rem",
  },
  subTitle: {},
}));

function Hero3({
  title,
  description,
  link,
  cta,
  backgroundColor,
  secondary,
  icone,
}) {
  const classes = useStyle();
  const {
    file: {
      childImageSharp: { fluid },
    },
  } = useStaticQuery(query);

  const theme = useTheme();

  return (
    <div className="hero3" style={{ margin: "0" }}>
      <div className={classes.flexCenter}>
        <div style={{ margin: "0 auto" }}>
          <Typography
            variant="h1"
            component="h1"
            gutterBottom
            className={classes.title}
          >
            {title}
          </Typography>
          <Typography
            variant="h6"
            component="h2"
            gutterBottom
            className={classes.subTitle}
          >
            {description}
          </Typography>

          {cta ? (
            <Button
              component={Link}
              color="primary"
              variant="contained"
              to={link}
              className={classes.btn}
            >
              {cta}
            </Button>
          ) : null}
        </div>
      </div>

      <Image className="hero3-image" objectFit="contain" fluid={fluid} style={{width:"80%"}} />
    </div>
  );
}

export default Hero3;
