import React from "react";
import Layout from "../components/Layout";
import Hero3 from "../components/Hero3";
import { Typography, Button } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
// import { Link, useStaticQuery } from "gatsby";
import Image from "gatsby-image";
import SwipeableViews from "react-swipeable-views";
import { graphql } from "gatsby";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import service from "../constants/service";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import Seo from "../components/Seo";
// import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    marginTop: 30,
  },
  sectionCenter: {
    ...theme.section.center,
  },
  btn: {
    marginTop: "2rem",
    fontSize: "1.2rem",
    padding: "10px 30px",
    borderRadius: "50px",
  },

  tabs: {
    backgroundColor: "#FCFCFF",
    // boxShadow: "0px 0px 0px 0px  !important",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export default function Home({ data }) {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <>
      <Seo title="Accueil" />
      <Layout>
        <main className="home-page" style={{ backgroundColor: "#FCFCFF" }}>
          <Hero3
            title="BOOSTEZ VOTRE MARKETING"
            description="Scalable vous permet de créer et d'optimiser rapidement des campagnes digitales qui incitent davantage vos visiteurs à agir."
            link="https://calendly.com/scalable-ads/45min"
            cta="réserver un appel"
            style={{ margin: "0px" }}
          />
          {/**************************************** */}

          <div className="googlePartnerContainer">
            <div className="googlePartnerTextContainer">
              <Typography
                className="googlePartnerText"
                align="center"
                variant="h4"
                component="h2"
                gutterBottom
              >
                AGENCE EXPERTE CERTIFIÉ
              </Typography>
              <Typography
                className="googlePartnerText"
                align="center"
                variant="h3"
                component="h2"
                gutterBottom
              >
                GOOGLE PARTNER
              </Typography>
            </div>
            <div>
              <Image
                className="googlePartnerImage"
                fluid={data.googlePartner.childImageSharp.fluid}
              />
            </div>
          </div>

          {/**************************************** */}

          <div
            style={{
              textAlign: "center",
              backgroundColor: "#FCFCFF",
              marginTop: "50px",
            }}
          >
            <div
              className={classes.sectionCenter}
              style={{
                textAlign: "center",
                marginBottom: "60px",
                paddingTop: "40px",
                backgroundColor: "#FCFCFF",
              }}
            >
              <Typography
                variant="h3"
                component="h2"
                gutterBottom
                className={classes.title}
              >
                ACHAT MÉDIAS DIGITAL
              </Typography>
              <div className="underline"></div>
              <Typography
                variant="h6"
                component="h3"
                gutterBottom
                className={classes.subTitle}
              >
                Scalable fait des achats médias complexes mieux que quiconque.
                Notre approche technologique unique pour combiner l'achat de
                médias avec des éléments créatifs personnalisés donnent un ROI
                et un ROAS améliorés pour vos campagnes numériques payantes
              </Typography>
            </div>

            <div className={classes.tabs}>
              <div>
                <AppBar position="static" color="transparent">
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="secondary"
                    // variant="fullWidth"
                    centered

                    // aria-label="full width tabs example"
                  >
                    {service.map((el, index) => {
                      return (
                        <Tab
                          key={index}
                          // label={el.title}
                          icon={el.icon}
                          {...a11yProps(el.id)}
                        />
                      );
                    })}
                  </Tabs>
                </AppBar>
              </div>
              <div>
                <SwipeableViews
                  axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                  index={value}
                  onChangeIndex={handleChangeIndex}
                >
                  {service.map((el, index) => {
                    let image = "image" + index;
                    // console.log(data[image]);
                    return (
                      <TabPanel
                        key={index}
                        style={{ backgroundColor: "#FCFCFF" }}
                        value={value}
                        index={el.id}
                        dir={theme.direction}
                      >
                        <div className="SectionAchatMedia">
                          <div className="AchatMedia">
                            <Typography
                              variant="h3"
                              component="h2"
                              gutterBottom
                            >
                              {`Publicité ${el.title}`}
                            </Typography>
                            <div className="underline"></div>
                            <Typography variant="body1" component="p">
                              {el.text}
                            </Typography>
                            {/* <Button
                          component={Link}
                          color="secondary"
                          variant="contained"
                          to="/contact/"
                          className={classes.btn}
                          disabled
                        >
                          En Savoir Plus
                        </Button> */}
                          </div>

                          <Image
                            className="AchatMediaImage"
                            fluid={data[image].childImageSharp.fluid}
                          />
                        </div>
                      </TabPanel>
                    );
                  })}
                </SwipeableViews>
              </div>
            </div>
          </div>

          {/**************************************** */}

          <div style={{ background: "#fff" }}>
            <div
              className={classes.sectionCenter}
              style={{
                textAlign: "center",
                marginBottom: "60px",
                paddingTop: "40px",
                paddingBottom: "40px",
                marginBottom: "0",
              }}
            >
              <Typography
                variant="h3"
                component="h2"
                gutterBottom
                className={classes.title}
              >
                + DE 80 MARQUES
              </Typography>
              <Typography
                variant="h6"
                component="h2"
                gutterBottom
                className={classes.title}
              >
                Nous ont fait confiance
              </Typography>
              <div className="underline"></div>

              <div
                style={{
                  textAlign: "center",
                  marginBottom: "30px",
                  paddingLeft: "40px",
                  paddingRight: "40px",
                }}
              >
                <div className="images-clients">
                  <Image fluid={data.henkel.childImageSharp.fluid} />
                  <Image fluid={data.ooredoo.childImageSharp.fluid} />
                  <Image fluid={data.oppo.childImageSharp.fluid} />
                  <Image fluid={data.danone.childImageSharp.fluid} />
                  <Image fluid={data.renaultlogo.childImageSharp.fluid} />
                  <Image fluid={data.pepsi.childImageSharp.fluid} />
                  <Image fluid={data.zara.childImageSharp.fluid} />
                  <Image fluid={data.vachequirit.childImageSharp.fluid} />
                  <Image fluid={data.ifri.childImageSharp.fluid} />
                  <Image fluid={data.lg.childImageSharp.fluid} />
                  {/************ ** */}
                  <Image fluid={data.nestle.childImageSharp.fluid} />
                  <Image fluid={data.rouiba.childImageSharp.fluid} />
                  <Image fluid={data.iris.childImageSharp.fluid} />
                  <Image fluid={data.chipsmahboul.childImageSharp.fluid} />
                  <Image fluid={data.president.childImageSharp.fluid} />
                </div>
              </div>
            </div>
          </div>

          {/************ *** */}

          <div className="solutionsContainer">
            <Typography
              style={{ paddingTop: "40px" }}
              variant="h3"
              component="h2"
              gutterBottom
              align="center"
            >
              NOS SOLUTIONS DE PILOTAGE
            </Typography>
            <Typography
              variant="h6"
              component="h3"
              gutterBottom
              align="center"
              // style={{ color: "#d9d9db" }}
            >
              Des outils avancés derniers cris pour soutenir notre expertise
            </Typography>

            <div className="solutions">
              <div style={{ padding: "15%" }}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  align="center"
                  style={{ fontWeight: "700" }}
                >
                  Campaign Manager
                </Typography>
                <Typography
                  variant="body1"
                  component="h2"
                  gutterBottom
                  style={{ fontWeight: "400", textAlign: "justify" }}
                >
                  Scalable utilise le serveur publicitaire Campaign Manager (ex
                  Doubleclick Campaign Manager) pour gérer vos plans média,
                  télécharger vos créations (bannières, vidéos, etc), et
                  programmer les règles de diffusion de vos annonces. Campaign
                  Manager est intégré à la Google Marketing Platform et
                  fonctionne donc en synergie avec Search Ads 360 et Display &
                  Video 360.
                </Typography>
              </div>
              <Image
                className="solutions-image-1"
                fluid={data.campaignManager.childImageSharp.fluid}
              />
            </div>

            <div className="solutions">
              <Image
                className="solutions-image-2"
                fluid={data.displayvideo360.childImageSharp.fluid}
              />
              <div style={{ padding: "15%" }}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  align="center"
                  style={{ fontWeight: "700" }}
                >
                  Display & video360{" "}
                </Typography>
                <Typography
                  variant="body1"
                  component="h2"
                  gutterBottom
                  style={{ fontWeight: "400", textAlign: "justify" }}
                >
                  Scalable utilise en interne le DSP Display & Video 360 (ex
                  DoubleClick Bid Manager), pour vous permettre d'acheter via la
                  programmatique des emplacements display sur tous les
                  Ad-Exchanges du marché, au CPM et en temps réel (RTB). Nous
                  ciblons pour vous des audiences spécifiques, en lien avec
                  votre activité, pour diffuser dans l'ensemble des formats
                  disponibles : Bannière, Native, App, Audio, Vidéo.
                </Typography>
              </div>
            </div>
          </div>

          {/* <WhyTobeOnWeb /> */}
        </main>
      </Layout>
    </>
  );
}

export const query = graphql`
  {
    campaignManager: file(relativePath: { eq: "campaignManager.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    displayvideo360: file(relativePath: { eq: "displayvideo360.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    googlePartner: file(relativePath: { eq: "googlePartner.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    henkel: file(relativePath: { eq: "henkel.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ifri: file(relativePath: { eq: "ifri.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    lg: file(relativePath: { eq: "lg.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    zara: file(relativePath: { eq: "zara.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    vachequirit: file(relativePath: { eq: "vachequirit.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    pepsi: file(relativePath: { eq: "pepsi.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ooredoo: file(relativePath: { eq: "ooredoo.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    oppo: file(relativePath: { eq: "oppo.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    danone: file(relativePath: { eq: "danone.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    renaultlogo: file(relativePath: { eq: "renaultlogo.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    nestle: file(relativePath: { eq: "nestle.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    rouiba: file(relativePath: { eq: "rouiba.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    iris: file(relativePath: { eq: "iris.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    chipsmahboul: file(relativePath: { eq: "chipsmahboul.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    president: file(relativePath: { eq: "president.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image0: file(relativePath: { eq: "image0.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image1: file(relativePath: { eq: "image1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image2: file(relativePath: { eq: "image2.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image3: file(relativePath: { eq: "image3.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image4: file(relativePath: { eq: "image4.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image5: file(relativePath: { eq: "image5.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
