import React from "react";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import YouTubeIcon from "@material-ui/icons/YouTube";
import GavelSharpIcon from "@material-ui/icons/GavelSharp";
import { Icon, InlineIcon } from "@iconify/react";
import snapchatIcon from "@iconify-icons/mdi/snapchat";
// import { StaticImage } from "gatsby-plugin-image";
// import { GatsbyImage, getImage } from "gatsby-plugin-image";
export default [
  {
    id: 0,
    icon: <FacebookIcon style={{ color: "#333" }} />,
    title: "Facebook",
    text: `Connaître les tenants et les aboutissants de Facebook est non seulement important mais crucial pour le succès de votre stratégie média payante. Nos consultants certifié Facebook utilisent une combinaison de technologie propriétaire, de données uniques et des années d'expérience pour maximiser l'efficacité et générer des résultats sur la plus grande plate-forme de médias sociaux au monde  `,
    image:
      "https://1d6uol1756s63dcmhp5yd8og-wpengine.netdna-ssl.com/wp-content/uploads/2018/05/media-buying-facebook.svg",
  },

  {
    id: 1,
    icon: <InstagramIcon style={{ color: "#333" }} />,
    title: "Instagram",
    text: `Nous avons une expérience croissante de la notoriété de la marque, de la considération et des conversions pour les grandes marques et les annonceurs à réponse directe sur Instagram `,
    image:
      "https://1d6uol1756s63dcmhp5yd8og-wpengine.netdna-ssl.com/wp-content/uploads/2018/05/media-buying-instagram.svg",
  },
  {
    id: 2,
    icon: <YouTubeIcon style={{ color: "#333" }} />,
    title: "Youtube",
    text: `Plus d'un milliard d'heures de contenu vidéo sont visionnés chaque jour sur youtube, il est plus difficile que jamais de garantir une expérience de marque pour les annonceurs. La couche technologique de ciblage contextuel Scalable crée un alignement sans précédent de l'audience tout en garantissant que le message de votre marque est diffusé par rapport au contenu sécurisé de la marque sur YouTube.`,
    image:
      "https://1d6uol1756s63dcmhp5yd8og-wpengine.netdna-ssl.com/wp-content/uploads/2018/05/media-buying-display.svg",
  },
  {
    id: 3,
    icon: (
      <Icon
        icon={snapchatIcon}
        style={{ color: "#333" }}
        width="22"
        height="22"
      />
    ),
    title: "Snapchat",
    text: `Scalable était l'un des premiers annonceurs sur Snapchat en algérie. Cela signifie que nous avons le recul nécessaire pour répondre au mieux à vos besoins.`,
    image:
      "https://1d6uol1756s63dcmhp5yd8og-wpengine.netdna-ssl.com/wp-content/uploads/2018/05/media-buying-snapchat.svg",
  },
  {
    id: 4,
    icon: <LinkedInIcon style={{ color: "#333" }} />,
    title: "Linkedin",
    text: `Le réseau commercial de LinkedIn offre de précieuses opportunités de parler aux professionnels d'une manière unique. Scalable aide les clients qui cherchent à s'engager, à accroître leur considération et à alimenter leurs achats sur LinkedIn `,
    image:
      "https://1d6uol1756s63dcmhp5yd8og-wpengine.netdna-ssl.com/wp-content/uploads/2018/05/media-buying-linkedin.svg",
  },
  {
    id: 5,
    icon: <GavelSharpIcon style={{ color: "#333" }} />,
    title: "Display",
    text: `Le Display programmatique vous permet de diffuser les bonnes annonces aux bonnes personnes au bon moment et, plus important encore, à la bonne étape du parcours client. Chez Scalable, nous avons une riche expérience pour planifier, livrer, optimiser et générer des rapports sur les campagnes RTB, afin d'atteindre les KPI de votre entreprise`,
    image:
      "https://1d6uol1756s63dcmhp5yd8og-wpengine.netdna-ssl.com/wp-content/uploads/2018/05/media-buying-display.svg",
  },
];
